<template>
  <div class="page_container">
    <div class="article">
      <div class="title">{{ article.title }}</div>
      <div class="tools">
        <div class="author_info">
          <div class="author">
            <img v-if="article.iconUrl" :src="article.iconUrl"/>
            <img v-else src="@/assets/image/authorImg.png">
            <span>{{ article.authorName }}</span>
          </div>
          <span class="time">{{ article.relaseTime }}</span>
        </div>
        <div>
          <span><i class="fa fa-eye"></i>{{ article.scanCnt2 }}</span>
          <span><i class="fa fa-thumbs-o-up"></i>{{ isPraise ? (article.praiseCnt + 1) : article.praiseCnt }}</span>
          <span style="position: relative;margin-left:30px" class="share" @mouseout="leaveImg" @mouseover="intoImg(article)">
                <i class="fa fa-share-alt"></i>{{article.shareCnt}}
                <div class="share_img">
                  <div :ref="'qr'+article.uuid"></div>
                </div>
              </span>
        </div>
      </div>
      <div v-if="article.videoUrl" style="margin-top: 10px;padding-bottom:10px">
        <video style="width: 100%;height: 380px" :src="article.videoUrl" controls="controls">
          您的浏览器不支持 video 标签
        </video>
      </div>
      <p class="content" id='content' ref="content" v-html="article.context"></p>
      <div class="source" v-if="article.source">来源于：{{ article.source }}</div>
      <ul class="tags">
        <li v-for="(item, index) in article.labelList" :key="index" @click="shipTagList(item.ltName)">{{
            item.ltName
          }}
        </li>
      </ul>
      <div class="is_collect">
        <vue-star-plus v-model="isPraise" style="margin: 0 auto">
          <i slot="icon" :style="{
          color: (isPraise ? '#fff' : '#fff'),
          padding: ('8px 16px'),
          borderRadius: ('45px'),
          cursor: 'pointer',
          userSelect: 'none',
          background: (isPraise ? '#c9c9c9' : '#3e35e7')
        }" class="fa fa-thumbs-up" @click="praise"><span
              style="font-size: 0.8rem;margin-left: 3px;position: relative;top: -1.5px">{{
              isPraise ? '已点赞' : '点赞'
            }}</span></i>
        </vue-star-plus>
      </div>

      <div class="recommend">
        <div class="tools">
          <h2>相关推荐</h2>
          <el-button size="mini" @click="getOtherRecommend">换一换<i class="iconfont icon-xindongfang-shuaxintubiao"></i>
          </el-button>
        </div>

        <div></div>
        <ul class="recommend_info">
          <li v-for="(item, index) in labelList" :key="index" @click="skipOtherDetails(item)">
            <img :src="item.imageUrl">
            <div>{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="aside_area" ref="asideArea">
      <div>
        <div class="describe_card">
          <div class="top">
            <img v-if="article.iconUrl" :src="article.iconUrl"/>
            <img v-else src="@/assets/image/authorImg.png">
            <div class="nick_name">{{ article.authorName }}</div>
            <button v-if="!isAttention" @click="attention">关注我</button>
            <button v-else style="background: #c9c9c9" @click="notAttention">取消关注</button>
          </div>
          <div class="bottom">
            <div class="crosswise">
              <div>
                <div>{{ article.artNums }}</div>
                <span>文章数量</span>
              </div>
              <div>
                <div>{{ article.scanCntTotal2 }}</div>
                <span>浏览量</span>
              </div>
              <div>
                <div v-if="article.thumbsUpNums  == undefined">
                  {{ isPraise ? ((article.thumbsUpNums = 0) + 1) : (article.thumbsUpNums = 0) }}
                </div>
                <div v-else>{{ isPraise ? (article.thumbsUpNums + 1) : article.thumbsUpNums }}</div>
                <span>获赞数</span>
              </div>
              <div>
                <div>{{ article.fansNums }}</div>
                <span>粉丝数</span>
              </div>
            </div>
            <div class="describe">
              <div>作者简介: {{ auIntroduce }}</div>
            </div>
            <div class="more">
              <span @click="loadAuthorDetails">查看更多</span>
              <i class="fa fa-caret-right"></i>
            </div>
          </div>
        </div>
        <hot-article></hot-article>
        <collec-tip ref="colletTip"></collec-tip>
      </div>
    </div>
    <popup v-if="isShowPopup" :is-show.sync="isShowPopup"></popup>
  </div>
</template>

<script>
import HotArticle from "../../components/HotArticle/HotArticle";
import CollecTip from "../../components/CollecTip/CollecTip";
import Popup from "../../views/popup/Popup";
import QRCode from 'qrcodejs2'

let pageIsPraise = false;

export default {
  name: "index",
  components: {CollecTip, HotArticle, Popup},
  data: () => ({
    isShowPopup: false,
    article: {
      relaseTime: '',
      title: '',
      authorName: '',
      scanCnt: '',
      praiseCnt: '',
      context: '',
      source: '',
      artNums: '',
      videoUrl: '',
      labelList: []
    },
    auId: undefined,
    auIntroduce: '',
    labelList: [],
    isPraise: false,
    isAttention: false,
    recommendInfo: {
      currentPage: 1,
      currentPageSize: 4,
      totalPage: 100
    },
    clientHeight: undefined,
    codeBaseUrl:"https://www.metaversehub.net/nft-api/h5/article/",
    imgTimer:null
  }),
  async mounted() {
    this.clientHeight = document.documentElement.clientHeight
    let uuid = this.$route.params.uuid
    await this.getDetail(uuid)
    this.createImg(this.article);
    window.addEventListener('scroll', this.fixedAside, true)
    this.$nextTick(() => {
      setTimeout(() => {
        this.fixedAside()
      }, 0)
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.fixedAside, true);
  },
  async beforeRouteUpdate(to, from, next) {
    this.getDetail(to.params.uuid);
    next()
  },
  methods: {
    intoImg(obj){
      this.imgTimer=setTimeout(()=>{
        this.addTime(obj);
      },200)
    },
    leaveImg(){
      clearInterval(this.imgTimer)
    },
    addTime(obj){
      this.axios.post('/article/share', {uuid: obj.uuid}).then(res=>{
        console.log('res',res)
      })
    },
    createImg(obj){
      let _this=this;
      new QRCode(this.$refs['qr'+obj.uuid], {
            text: _this.codeBaseUrl+obj.atUuid, // 需要转换为二维码的内容
            width: 88,
            height: 88,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
    fixedAside() {
      // console.log(this.$refs.asideArea.style.height - 331)
      // console.log(document.documentElement.scrollHeight)
      // console.log(document.documentElement.scrollTop)
      // console.log(document.documentElement.clientHeight)
      let scrollBottom =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop -
          document.documentElement.clientHeight;
      if (scrollBottom <= 281 && scrollBottom >= 200) {
        // console.log('1')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px - 60px)'
      } else if (scrollBottom < 200 && scrollBottom >= 100) {
        // console.log('2')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px - 160px)'
      } else if (scrollBottom < 100 && scrollBottom >= 50) {
        // console.log('3')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px - 190px)'
      } else if (scrollBottom < 50) {
        // console.log('4')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px - 263px)'
      } else {
        // console.log('5')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px)'
      }
      if (document.documentElement.scrollTop == 0 && scrollBottom > 281 && this.clientHeight - 331 > 281) {
        // console.log('6')
        this.$refs.asideArea.style.height = 'calc(100vh - 100px)'
      }


    },
    getDetail(uuid) {
      document.documentElement.scrollTop = 0
      return this.axios.get('/article/detail/' + uuid).then(res => {
        this.auId = res.data.auId
        this.article = res.data
        this.article.uuid = uuid
        if(null == this.article.fansNums || 0 == this.article.fansNums){
          this.article.fansNums = '—'
        }
        this.axios.get('/sys/author/info', {params: {auId: res.data.auId}}).then(res => {
          if (res.code == 0) {
            this.auIntroduce = res.data.introduce
          }
        })

        // 读取推荐列表
        this.getRecommendList()

        // 读取当前作者是否被关注
        this.axios.post('/sys/author/is-attention', {auId: res.data.auId}).then(res => {
          if (res.code == 401) {
            this.isAttention = false
          }
          if (res.code == 0) {
            res.isAttention == 1 ? this.isAttention = true : this.isAttention = false
          }
        })

      })
    },
    getRecommendList() {
      let params = {
        uuid: this.article.uuid,
        currentPage: 1,
        currentPageSize: 4
      }
      this.axios.get('/article/recommend', {params}).then(res => {
        this.recommendInfo.currentPage = res.page.currPage
        this.recommendInfo.totalPage = res.page.totalPage
        this.labelList = res.page.list
      })
    },
    getOtherRecommend() {
      this.recommendInfo.currentPage += 1
      let params = {
        uuid: this.article.uuid,
        currentPage: this.recommendInfo.currentPage + 1,
        currentPageSize: 4
      }
      this.axios.get('/article/recommend', {params}).then(res => {
        this.labelList = res.page.list
      })
    },
    loadAuthorDetails() {
      this.$router.push('/author-article-list/' + this.auId)
    },
    // 关注作者
    attention() {
      let auId = this.article.auId
      this.axios.get('/sys/author/attention', {params: {auId}}).then(res => {
        if (res.code == 401) {
          this.isShowPopup = true
        }
        if (res.code == 0) {
          this.article.fansNums += 1
          this.isAttention = true
        }
      })
    },
    // 取消关注
    notAttention() {
      // this.$refs.colletTip.close()
      let auId = this.article.auId
      this.article.fansNums -= 1
      this.isAttention = false
      this.axios.get('/sys/author/attention-cancel', {params: {auId: auId}})
    },
    // 点赞
    praise() {
      let params = {
        uuid: this.article.uuid
      }
      if (!this.isPraise && !pageIsPraise) {
        pageIsPraise = true
        this.axios.get('/article/praise', {params})
      }
    },
    // 其他详情
    skipOtherDetails(item) {
      this.$router.push('/article-detail/' + item.atUuid)
    },
    shipTagList(name) {
      this.$router.push({name: 'TagList', params: {tagType: name}})
    }
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  display: flex;
  margin-bottom: 40px;

  .article {
    width: 787px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 40px;
    overflow: hidden;
    max-width: 100%;


    .title {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      margin: 25px 0 32px 0;
      letter-spacing: 1px;
    }

    .tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-bottom: 10px;
      color: #666666;
      border-bottom: 1px solid #999999;

      .author_info {
        display: flex;
        align-items: center;

        .author {
          display: flex;
          margin-right: 20px;
          color: #999999;
          font-size: 14px;
          align-items:center;
          img {
            width: 22px;
            height: 22px;
            object-fit: cover;
          }
        }

        .time {
          font-size: 14px;
          color: #999999;
          margin-top:3.5px;
        }
      }


      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }

      > span {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #000000;
        margin-right: 30px;
        opacity: 1;
      }

      .fa {
        margin-right: 10px;
      }

      .fa-thumbs-o-up {
        margin-left: 30px;
      }
      .share {
          position: relative;
          cursor: pointer;
          &:hover .share_img {
            visibility: visible;
            transition: visibility 1s ease;
          }
          .fa-share-alt{
            margin-right: 10px;
          }
          .share_img {
            visibility: hidden;
            cursor: pointer;
            box-sizing: border-box;
            padding: 6px;
            position: absolute;
            left: -104px;
            top: -60px;
            background-color: #FFFFFF;
            margin: 0;
            width: 100px;
            height: 100px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
            overflow: hidden
          }
        }
    }

    .content {
      width: 100%;
      max-width: 100%;
      font-size: 0.9rem;
      word-wrap: break-word;

      /deep/ img {
        max-width: 100%;
      }

      /deep/ p {
       margin-top: 30px;
      }

      /deep/ ul {
        list-style: disc;
        margin: 0;
        padding-left: 24pt;
      }

    }


    .source {
      margin-top: 80px;
      font-size: 0.9rem;
    }

    .tags {
      display: flex;
      margin: 30px 0 20px;
      flex-wrap: wrap;
      cursor: pointer;


      li {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        text-align: center;
        background: #ECECEC;
        font-size: 0.6rem;
        color: #3E35E7;
        border-radius: 12px;
        padding: 0 8px;
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .is_collect {
      height: 200px;
      display: flex;
      justify-content: center;

      .fa-heart {
        background: #3e35e7;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 50px;
        color: #FFFFFF;
        z-index: 9;
        user-select: none;
      }

    }

    .recommend {
      .tools {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-button {
          padding: 6px 10px;
          background: #3e35e7;
          color: #FFFFFF;
          border: none;
          border-radius: 45px;
          display: flex;
          align-items: center;
          font-size: 14px;

          .iconfont {
            margin-left: 3px;
          }
        }
      }

      .recommend_info {
        display: flex;
        margin-top: 15px;
        height: 118px;

        li {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          width: 150px;

          img {
            width: 100%;
            height: 86px;
            object-fit: cover;
            border-radius: 4px;
          }

          div {
            font-size: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #000000;
          }
        }

        li:not(:last-child) {
          margin-right: 35px;
        }

      }
    }

  }


  .aside_area {
    position: fixed;
    margin-left: 787px;
    height: calc(100vh - 100px);
    min-height: calc(100vh - 100px - 263px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 283px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;

      .describe_card {
        min-height: 360px;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 30px 20px 10px;
        margin-bottom: 40px;

        .top {
          text-align: center;

          img {
            width: 96px;
            height: 96px;
            object-fit: cover;
            border-radius: 50%;
          }

          .nick_name {
            font-size: 18px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 15px 0;
          }

          button {
            background: #3E35E7;
            color: #FFFFFF;
            outline: none;
            border: none;
            padding: 8px 20px;
            border-radius: 50px;
            cursor: pointer;
          }
        }

        .bottom {
          margin-top: 30px;

          .crosswise {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #999999;

            > div :first-child {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              text-align: center;
            }
          }

          > div {
            font-size: 18px;
            line-height: 36px;
          }

          .describe {
            font-size: 14px;
            color: #333333;
            line-height: 26px;
            position: relative;
            background: #F8F8F8;
            padding: 10px;
            border-radius: 8px;
            box-sizing: border-box;
            min-height: 99px;


            > div:first-child {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              word-break: break-all;
            }
          }

          .more {
            text-align: center;
            font-size: 0.9rem;
            color: #3E35E7;
            cursor: pointer;

            .fa {
              margin-left: 6px;
              font-size: 1.2rem;
              line-height: 35px;
              position: relative;
              top: 1.8px;
            }
          }
        }

      }
    }


  }
}

</style>
