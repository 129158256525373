<template>
  <!-- 关注提示 -->
  <div class="is_collect" ref="isCollect">
    <i class="fa fa-check-circle"></i>
    <div>关注成功</div>
    <span class="tip">感谢你的关注～</span>
    <i class="fa fa-close" @click="close"></i>
  </div>
</template>

<script>
export default {
  name: "CollecTip",
  data: () => ({}),
  methods: {
    open() {
      this.$refs.isCollect.style.display = 'flex'
    },
    close() {
      this.$refs.isCollect.style.display = 'none'
    }
  }
}
</script>

<style lang="scss" scoped>
.is_collect {
  background: #FFFFFF;
  height: 200px;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: Source Han Sans CN;
  letter-spacing: 1px;
  position: relative;
  display: none;

  .fa-check-circle {
    color: #3E35E7;
    font-size: 100px;
  }

  div {
    font-size: 18px;
    font-weight: bold;
    margin: 6px 0;
  }

  .tip {
    font-size: 12px;
    color: #000000;
  }

  .fa-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: #BDBEBD;
    cursor: pointer;

    &:hover {
      color: #3e35e7;
    }
  }
}
</style>
